import React from 'react';
import Iframe from 'react-iframe'
import loading from './../assets/images/loading.svg';

class HomeScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			is_load: false
		}
	}

	componentDidMount() {
		let self = this;

		const subdomain = 'demo';
		const frame = document.getElementById('frame');

		// frame.src = `https://${subdomain}.readyplayer.me/avatar?frameApi`;
		frame.src = `https://${subdomain}.readyplayer.me/avatar?frameApi&clearCache`;

		window.addEventListener('message', subscribe);
		document.addEventListener('message', subscribe);


		function subscribe(event) {
			const json = parse(event);
			console.log("json:::::::::::::", json);

			if (json?.source !== 'readyplayerme') {
				return;
			}

			// Susbribe to all events sent from Ready Player Me once frame is ready
			if (json.eventName === 'v1.frame.ready') {
				frame.contentWindow.postMessage(
					JSON.stringify({
						target: 'readyplayerme',
						type: 'subscribe',
						eventName: 'v1.**'
					}),
					'*'
				);
			}

			if (json.eventName === 'v1.avatar.exported') {

				self.setState({ is_load: true });
				const params =
				{
					// Replace with the .glb URL for the desired avatar.
					model: json.data.url,
					// Type of portrait to render.
					scene: "fullbody-portrait-v1",
					// Optional: Pose. Default: T-pose.
					armature: "ArmatureTargetMale",
					// Optional: Facial expression. Default: Empty.
					"blendShapes": { "Wolf3D_Avatar": { "mouthSmile": 0.2 } }
				}
				console.log(params);
				const http = new XMLHttpRequest()
				http.open("POST", "https://render.readyplayer.me/render")
				http.setRequestHeader("Content-type", "application/json")
				http.send(JSON.stringify(params))
				http.onload = function () {
					var image = JSON.parse(http.responseText)["renders"]
					var outputImg = document.createElement('a')
					outputImg.href = image
					outputImg.setAttribute("download", "image.png");
					outputImg.target = '_blank';
					document.body.appendChild(outputImg)
					outputImg.click();
					self.setState({ is_load: false });
				}

			}
		}

		function parse(event) {
			try {
				return JSON.parse(event.data);
			} catch (error) {
				return null;
			}
		}

		function displayIframe() {
			document.getElementById('frame').hidden = false;
		}

	}

	render() {

		return (
			<>
				{this.state.is_load ?
					<div>
						<img src={loading} style={{marginTop:200}}/>
						<p style={{color:"#fff",textAlign:"center"}}>Processing Your Avatar..</p>
					</div>
					:
					<Iframe
						width="99.6%"
						height="800px"
						id="frame" class="frame"
						clearCache
						allow="geolocation *; camera *; microphone *; clipboard-read; clipboard-write;"
						display="block"
						position="relative"
					/>
				}
			</>
		);
	}
}

export default HomeScreen;
